import * as React from "react";

function EditIcon({ ...props }: React.ComponentProps<"svg">) {
  return (
    <svg width={24} height={24} fill="none" {...props}>
      <rect x={3} y={19.5} width={18} height={2.25} rx={1.125} fill="#0369A1" />
      <path
        d="M18.21 6.332l-2.043-2.044a1.844 1.844 0 00-2.604 0l-1.687 1.687 4.648 4.648 1.687-1.687c.347-.348.539-.81.539-1.302s-.192-.955-.54-1.302zM5.807 12.257l-.55 4.167a.727.727 0 00.817.817l4.167-.55a.726.726 0 00.42-.207l4.83-4.832-4.647-4.647-4.831 4.831a.727.727 0 00-.207.42h.001z"
        fill="#0369A1"
      />
    </svg>
  );
}

const MemoEditIcon = React.memo(EditIcon);
export default MemoEditIcon;
