import React from "react";
import { ComponentProps } from "react";
import { twMerge } from "tailwind-merge";

function CrossIcon(props: ComponentProps<"svg">) {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      aria-label="cross-icon"
      {...props}
      className={twMerge("fill-black", props.className)}
    >
      <path d="M15.567.393a1.413 1.413 0 00-1.995.04L8.22 6 2.391.395A1.411 1.411 0 00.433 2.427L6.222 8 .433 13.57a1.412 1.412 0 001.958 2.036L8.22 10l5.352 5.57a1.412 1.412 0 002.035-1.957L10.215 8l5.392-5.611a1.411 1.411 0 00-.04-2" />
    </svg>
  );
}

const MemoCrossIcon = React.memo(CrossIcon);
export default MemoCrossIcon;
