import React from "react";
import { ComponentProps } from "react";
import { twMerge } from "tailwind-merge";

function DragIcon(props: ComponentProps<"svg">) {
  return (
    <svg
      fill="none"
      height={15}
      width={15}
      viewBox="0 0 15 15"
      {...props}
      className={twMerge("stroke-neutral-400", props.className)}
    >
      <path d="M9.5 3a.5.5 0 110-1 .5.5 0 010 1zM9.5 8a.5.5 0 110-1 .5.5 0 010 1zM9.5 13a.5.5 0 110-1 .5.5 0 010 1zM5.5 3a.5.5 0 110-1 .5.5 0 010 1zM5.5 8a.5.5 0 110-1 .5.5 0 010 1zM5.5 13a.5.5 0 110-1 .5.5 0 010 1z" />
    </svg>
  );
}

const MemoDragIcon = React.memo(DragIcon);
export default MemoDragIcon;
