import React from "react";
import { ComponentProps } from "react";
import { twMerge } from "tailwind-merge";

function InfoIcon(props: ComponentProps<"svg">) {
  return (
    <svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      aria-label="info-icon"
      {...props}
      className={twMerge("fill-[#2D80AF]", props.className)}
    >
      <path d="M7.5 0A7.5 7.5 0 1015 7.5 7.509 7.509 0 007.5 0zm0 13.636A6.136 6.136 0 1113.636 7.5 6.144 6.144 0 017.5 13.636z" />
      <path d="M7.498 4.09a.682.682 0 100 1.364.682.682 0 000-1.363zM8.184 6.137H6.82v4.772h1.364V6.137z" />
    </svg>
  );
}

const MemoInfoIcon = React.memo(InfoIcon);
export default MemoInfoIcon;
