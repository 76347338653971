import * as React from "react";
import { twMerge } from "tailwind-merge";

function StarIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={12}
      height={12}
      viewBox="0 0 9 10"
      fill="none"
      {...props}
      className={twMerge("fill-[#F4C854]", props.className)}
    >
      <path d="M5.108 5.51l1.544.557a.084.084 0 010 .156l-1.544.558a1.862 1.862 0 00-1.12 1.12l-.556 1.544a.083.083 0 01-.157 0l-.557-1.544a1.863 1.863 0 00-1.119-1.12L.054 6.223a.083.083 0 010-.156L1.6 5.51a1.862 1.862 0 001.119-1.12l.557-1.545a.084.084 0 01.157 0l.557 1.546a1.863 1.863 0 001.119 1.12M8.07 2.08l.9.324a.05.05 0 010 .09l-.9.325a1.083 1.083 0 00-.65.65l-.323.9a.05.05 0 01-.091 0l-.324-.9a1.084 1.084 0 00-.65-.65l-.9-.324a.05.05 0 010-.091l.9-.324a1.083 1.083 0 00.65-.65l.324-.9a.05.05 0 01.09 0l.325.9a1.084 1.084 0 00.65.65" />
    </svg>
  );
}

const memoStarIcon = React.memo(StarIcon);
export default memoStarIcon;
