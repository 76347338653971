import { twMerge } from "tailwind-merge";

const BusinessIcon = ({
  width = 18,
  height = 18,
  className,
}: {
  width?: number;
  height?: number;
  className?: string;
}) => {
  return (
    <svg
      viewBox="0 0 18 18"
      width={width}
      height={height}
      className={twMerge("fill-stone-300", className)}
    >
      <path d="M1.769 13.944h2.554v2.612H1.769zm4.678 0H9v2.612H6.448zM1.769 9.778h2.554v2.611H1.769zm4.678 0H9v2.611H6.448zm4.677 0h5.427v6.778h-1.436V13.8h-2.553v2.756h-1.437zM1.769 5.611h2.554v2.611H1.769zm4.678 0H9v2.611H6.448zM1.769 1.445h2.554v2.611H1.769zm4.678 0H9v2.611H6.448zM0 0v18h18V8.222h-6.875V0z" />
    </svg>
  );
};

export default BusinessIcon;
