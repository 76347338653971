import { ComponentProps } from "react";

const HomeIcon = (props: ComponentProps<"svg">) => {
  return (
    <svg width={18} height={16} {...props}>
      <path d="M15.895 9L9.302 3.375a.466.466 0 00-.597 0L2.107 9c-.05.04-.077.1-.077.162v6.62c0 .122.1.218.226.218h4.637a.22.22 0 00.226-.218v-4.227c0-.48.407-.874.904-.874H9.98c.497 0 .903.393.903.874v4.227c0 .122.1.218.226.218h4.637a.22.22 0 00.226-.218v-6.62A.207.207 0 0015.895 9z" />
      <path d="M17.712 6.816L10.196.436a1.852 1.852 0 00-2.386 0L.29 6.816A.81.81 0 00.25 8.02c.32.31.831.323 1.17.035l6.982-5.943a.92.92 0 011.193 0l6.982 5.943a.88.88 0 001.17-.035.81.81 0 00-.04-1.205h.005z" />
    </svg>
  );
};
export default HomeIcon;
