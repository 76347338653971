import { ComponentProps } from "react";
import { twMerge } from "tailwind-merge";

function TelegramIcon(props: ComponentProps<"svg">) {
  return (
    <svg
      width={16}
      height={14}
      viewBox="0 0 16 14"
      fill="none"
      {...props}
      className={twMerge("fill-slate-600", props.className)}
    >
      <path d="M1.078 6.018C6.002 3.742 12.373.966 13.252.582c2.307-1.006 3.015-.813 2.662 1.414-.253 1.6-.985 6.9-1.567 10.198-.346 1.956-1.122 2.188-2.343 1.341-.587-.407-3.549-2.466-4.191-2.95-.587-.44-1.397-.97-.382-2.014.362-.371 2.73-2.748 4.574-4.598.242-.242-.062-.641-.34-.447a2149.888 2149.888 0 00-6.373 4.452c-.663.474-1.3.69-2.442.345-.864-.26-1.707-.571-2.036-.69-1.264-.456-.964-1.047.264-1.615z" />
    </svg>
  );
}

export default TelegramIcon;
