import { twMerge } from "tailwind-merge";

const HamburgerIcon = ({
  className = "",
  onClick,
}: {
  className?: string;
  onClick?: () => void;
}) => {
  return (
    <svg
      width={19}
      height={15}
      viewBox="0 0 19 15"
      className={twMerge("fill-[#3ad29f]", className)}
      onClick={onClick}
    >
      <path
        id="Combined_Shape"
        data-name="Combined Shape"
        d="M1,14a1,1,0,0,1-1-1V12a1,1,0,0,1,1-1H18a1,1,0,0,1,1,1v1a1,1,0,0,1-1,1ZM1,8A1,1,0,0,1,0,7V6A1,1,0,0,1,1,5H18a1,1,0,0,1,1,1V7a1,1,0,0,1-1,1ZM1,2A1,1,0,0,1,0,1V0A1,1,0,0,1,1-1H18a1,1,0,0,1,1,1V1a1,1,0,0,1-1,1Z"
        transform="translate(0 1)"
      />
    </svg>
  );
};

export default HamburgerIcon;
