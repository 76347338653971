import { twMerge } from "tailwind-merge";

const CheckIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      width="57"
      height="50"
      viewBox="0 0 57 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={twMerge("fill-white", className)}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M47.1704 2.03289L19.5541 36.033L9.51514 25.4268C8.49143 24.3537 7.10382 23.7336 5.64555 23.6976C4.18728 23.6617 2.77259 24.2126 1.70046 25.2341C1.18094 25.7304 0.762971 26.3293 0.471213 26.9952C0.179455 27.6611 0.0198193 28.3806 0.00173103 29.1113C-0.0163573 29.842 0.107469 30.569 0.365897 31.2495C0.624325 31.9299 1.01212 32.55 1.5064 33.0731L15.8221 48.2734C16.344 48.8218 16.9667 49.257 17.6537 49.5535C18.3407 49.85 19.0782 50.0018 19.8228 50H20.0243C20.8024 49.9733 21.5665 49.7785 22.2671 49.428C22.9678 49.0774 23.5895 48.5791 24.0921 47.9651L55.7389 9.10116C56.1994 8.54092 56.5461 7.89075 56.7584 7.18934C56.9707 6.48793 57.0442 5.74959 56.9745 5.01825C56.9049 4.28691 56.6936 3.57751 56.3531 2.93223C56.0126 2.28694 55.5499 1.71897 54.9925 1.26209C53.8518 0.325726 52.4038 -0.117166 50.9532 0.0265959C49.5027 0.170357 48.1627 0.889562 47.2152 2.03289"
      />
    </svg>
  );
};

export default CheckIcon;
