import React, { ComponentProps } from "react";

function PieChartIcon(props: ComponentProps<"svg">) {
  return (
    <svg
      width={14}
      height={14}
      fill="none"
      viewBox="0 0 14 14"
      {...props}
      className={props.className}
    >
      <path
        d="M7 0C3.14 0 0 3.14 0 7s3.14 7 7 7 7-3.14 7-7-3.14-7-7-7zm6.03 6.682H11.87v.01H7.35V.973a6.031 6.031 0 015.68 5.708zM.955 7A6.045 6.045 0 016.364.99v.957h.031v5.416l4.23 4.47a6.004 6.004 0 01-3.622 1.216A6.047 6.047 0 01.958 7.003L.955 7zm10.414 4.171l-.802-.801-.007.006-2.58-2.727h5.03a6.01 6.01 0 01-1.641 3.522z"
        fill="#BDBDBD"
      />
    </svg>
  );
}

const MemoPieChartIcon = React.memo(PieChartIcon);
export default MemoPieChartIcon;
