import { ComponentProps } from "react";
import { twMerge } from "tailwind-merge";

const LogoutIcon = (props: ComponentProps<"svg">) => (
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    className={twMerge("fill-[#C2D6D9]", props.className)}
  >
    <path d="M14.1807 1.0659e-05H6.13768C5.90476 1.0659e-05 5.68139 0.0925357 5.51669 0.257231C5.35199 0.421927 5.25947 0.645302 5.25947 0.878217C5.25947 1.11113 5.35199 1.33451 5.51669 1.4992C5.68139 1.6639 5.90476 1.75642 6.13768 1.75642H13.2333V13.2423H6.13768C6.02013 13.2386 5.90305 13.2587 5.79339 13.3011C5.68372 13.3436 5.58371 13.4077 5.49928 13.4896C5.41485 13.5714 5.34772 13.6694 5.30188 13.7777C5.25604 13.886 5.23242 14.0024 5.23242 14.12C5.23242 14.2377 5.25604 14.3541 5.30188 14.4624C5.34772 14.5707 5.41485 14.6687 5.49928 14.7505C5.58371 14.8324 5.68372 14.8965 5.79339 14.939C5.90305 14.9814 6.02013 15.0015 6.13768 14.9978H14.1823C14.3983 14.9985 14.6057 14.9134 14.759 14.7612C14.9123 14.6091 14.9988 14.4023 14.9997 14.1863V0.813226C14.9995 0.706003 14.9781 0.599877 14.9368 0.500931C14.8955 0.401985 14.8351 0.312165 14.759 0.236618C14.6829 0.161071 14.5927 0.101284 14.4934 0.0606817C14.3942 0.0200799 14.2879 -0.000537738 14.1807 1.0659e-05Z" />
    <path d="M2.92758 8.3846H10.4415C10.5578 8.3846 10.673 8.36169 10.7804 8.31718C10.8879 8.27267 10.9855 8.20742 11.0678 8.12518C11.15 8.04293 11.2153 7.94529 11.2598 7.83784C11.3043 7.73038 11.3272 7.6152 11.3272 7.49889C11.3272 7.38258 11.3043 7.26741 11.2598 7.15995C11.2153 7.05249 11.15 6.95485 11.0678 6.8726C10.9855 6.79036 10.8879 6.72512 10.7804 6.68061C10.673 6.6361 10.5578 6.61319 10.4415 6.61319H2.92758L4.65317 4.95843C4.7315 4.88269 4.79394 4.79211 4.83686 4.69196C4.87978 4.59182 4.90231 4.48413 4.90313 4.37518C4.90415 4.26777 4.88374 4.16124 4.84311 4.06181C4.80248 3.96239 4.74244 3.87205 4.6665 3.7961C4.51455 3.64374 4.30889 3.55707 4.09372 3.55474C3.87855 3.5524 3.67106 3.63457 3.51583 3.7836L0.250472 6.91314C0.171284 6.98912 0.108275 7.08032 0.0652315 7.18126C0.0221884 7.28221 0 7.39082 0 7.50056C0 7.6103 0.0221884 7.7189 0.0652315 7.81985C0.108275 7.9208 0.171284 8.012 0.250472 8.08797L3.5125 11.2167C3.66982 11.3669 3.88005 11.449 4.09753 11.4451C4.31501 11.4412 4.52217 11.3516 4.674 11.1959C4.74868 11.1189 4.80739 11.028 4.84675 10.9282C4.8861 10.8285 4.90533 10.722 4.90332 10.6148C4.9013 10.5076 4.87809 10.4018 4.83501 10.3036C4.79194 10.2055 4.72985 10.1168 4.65233 10.0427L2.92758 8.3846Z" />
  </svg>
);

export default LogoutIcon;
