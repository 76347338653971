import React, { ComponentProps } from "react";

function OutlineAccountIcon(props: ComponentProps<"svg">) {
  return (
    <svg
      width={14}
      height={15}
      fill="none"
      viewBox="0 0 14 15"
      {...props}
      className={props.className}
    >
      <path
        d="M7.14 7.777c2.317 0 4.202-1.745 4.202-3.89S9.457 0 7.141 0C4.824 0 2.939 1.745 2.939 3.89s1.885 3.89 4.202 3.89v-.003zm0-6.847c1.762 0 3.198 1.326 3.198 2.96 0 1.633-1.433 2.96-3.197 2.96S3.944 5.523 3.944 3.89C3.944 2.256 5.377.93 7.14.93zM10.254 8.493H3.749C1.684 8.493 0 10.05 0 11.965v.592c0 .8.703 1.447 1.563 1.447h10.874c.863 0 1.563-.65 1.563-1.447v-.592c0-1.913-1.68-3.472-3.75-3.472h.004zm2.745 4.064c0 .285-.251.517-.559.517H1.563c-.308 0-.559-.232-.559-.517v-.592c0-1.401 1.232-2.542 2.745-2.542h6.505c1.513 0 2.745 1.14 2.745 2.542v.592z"
        fill="#BDBDBD"
      />
    </svg>
  );
}

const MemoOutlineAccountIcon = React.memo(OutlineAccountIcon);
export default MemoOutlineAccountIcon;
