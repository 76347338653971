import { twMerge } from "tailwind-merge";

const CheckCircleIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      aria-label="check-circle-icon"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={twMerge("fill-[#58C1D7]", className)}
    >
      <path d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.311 6.09626L8.681 11.6093L7.002 9.88926C6.83067 9.715 6.59828 9.61422 6.35398 9.60825C6.10968 9.60228 5.87263 9.69158 5.693 9.85726C5.60524 9.93781 5.53461 10.0352 5.48532 10.1437C5.43602 10.2521 5.40908 10.3694 5.40609 10.4885C5.40311 10.6076 5.42414 10.726 5.46793 10.8368C5.51173 10.9476 5.57739 11.0484 5.661 11.1333L8.061 13.5913C8.14839 13.6802 8.25268 13.7508 8.36774 13.7989C8.48279 13.847 8.6063 13.8716 8.731 13.8713H8.765C8.89537 13.8672 9.02344 13.8358 9.14092 13.7791C9.25839 13.7225 9.36267 13.6418 9.447 13.5423L14.747 7.24226C14.824 7.1512 14.8818 7.04558 14.9171 6.9317C14.9523 6.81781 14.9643 6.69799 14.9522 6.57938C14.9402 6.46077 14.9044 6.3458 14.847 6.24132C14.7896 6.13684 14.7117 6.04499 14.618 5.97126C14.4272 5.82005 14.1852 5.7486 13.9428 5.77191C13.7005 5.79521 13.4765 5.91145 13.318 6.09626"
        fill="white"
      />
    </svg>
  );
};

export default CheckCircleIcon;
