import { twMerge } from "tailwind-merge";

const TickIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      className={twMerge("h-6 w-6", className)}
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth={2}
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
    </svg>
  );
};

export default TickIcon;
