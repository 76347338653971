import { twMerge } from "tailwind-merge";

const MenuDropdownIcon = ({
  color = "#000000",
  size = 1,
  className,
}: {
  color?: string;
  size?: number;
  className?: string;
}) => {
  const length = 5 * size;
  return (
    <div
      role="img"
      aria-label="DropdownIcon"
      style={{
        borderStyle: "solid",
        borderWidth: `${length}px ${length}px 0 ${length}px`,
        borderColor: `${color} transparent transparent transparent`,
      }}
      className={twMerge("h-0 w-0", className)}
    />
  );
};
export default MenuDropdownIcon;
