import { twMerge } from "tailwind-merge";

const AccountIcon = ({
  width = 18,
  height = 18,
  className,
}: {
  width?: number;
  height?: number;
  className?: string;
}) => {
  return (
    <svg
      id="Account_icon"
      data-name="Account icon"
      width={width}
      height={height}
      className={twMerge("fill-stone-300", className)}
      viewBox="0 0 18 18"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_10597"
            data-name="Rectangle 10597"
            width="18"
            height="18"
          />
        </clipPath>
      </defs>
      <g id="Group_11159" data-name="Group 11159" clipPath="url(#clip-path)">
        <path
          id="Path_15897"
          data-name="Path 15897"
          d="M8.585,0A4.585,4.585,0,1,1,4,4.585,4.585,4.585,0,0,1,8.585,0"
          transform="translate(0.585)"
        />
        <path
          id="Path_15898"
          data-name="Path 15898"
          d="M9.17,10C6.109,10,0,11.536,0,14.585v2.292H18.339V14.585C18.339,11.536,12.23,10,9.17,10"
          transform="translate(0 1.462)"
        />
      </g>
    </svg>
  );
};

export default AccountIcon;
