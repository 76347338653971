import React from "react";
import { ComponentProps } from "react";
import { twMerge } from "tailwind-merge";

function EllipsisVertical(props: ComponentProps<"svg">) {
  return (
    <svg
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      {...props}
      className={twMerge("h-6 w-6 fill-black", props.className)}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zm0 6a.75.75 0 110-1.5.75.75 0 010 1.5zm0 6a.75.75 0 110-1.5.75.75 0 010 1.5z"
      />
    </svg>
  );
}

const MemoEllipsisVertical = React.memo(EllipsisVertical);
export default MemoEllipsisVertical;
