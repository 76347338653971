import { twMerge } from "tailwind-merge";

const ChevronLeft = ({ className }: { className?: string }) => {
  return (
    <svg
      width="19"
      height="33"
      viewBox="0 0 19 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={twMerge("fill-[#58C1D7]", className)}
    >
      <path d="M0.670839 18.1666C0.240361 17.7185 -5.60395e-05 17.1213 -5.6068e-05 16.5C-5.60964e-05 15.8787 0.240361 15.2814 0.670838 14.8334L14.4216 0.690308C14.6313 0.472038 14.883 0.298378 15.1615 0.179756C15.44 0.0611306 15.7396 -1.13357e-05 16.0423 -1.13484e-05C16.3451 -1.1361e-05 16.6446 0.0611306 16.9232 0.179756C17.2017 0.298378 17.4533 0.472038 17.6631 0.690308C18.0936 1.13834 18.334 1.73557 18.334 2.3569C18.334 2.97822 18.0936 3.57545 17.6631 4.02349L5.5331 16.5L17.6631 28.9765C18.0936 29.4245 18.334 30.0218 18.334 30.6431C18.334 31.2644 18.0936 31.8616 17.6631 32.3097C17.4533 32.528 17.2017 32.7016 16.9232 32.8202C16.6447 32.9389 16.3451 33 16.0423 33C15.7396 33 15.44 32.9389 15.1615 32.8202C14.883 32.7016 14.6313 32.528 14.4216 32.3097L0.670839 18.1666Z" />
    </svg>
  );
};

export default ChevronLeft;
