import React from "react";
import { ComponentProps } from "react";
import { twMerge } from "tailwind-merge";

function CalendarIcon(props: ComponentProps<"svg">) {
  return (
    <svg
      width={17}
      height={17}
      viewBox="0 0 17 17"
      {...props}
      className={twMerge("fill-stone-300", props.className)}
    >
      <path d="M14.422 1.57h-1.136V0h-1.7v1.57H4.412V0h-1.7v1.57H1.578C.705 1.57 0 2.253 0 3.091V15.48C0 16.318.705 17 1.578 17h12.847c.87 0 1.577-.68 1.577-1.521V3.09c0-.838-.705-1.52-1.577-1.52h-.003zm-.133 13.784H1.711V5.85h12.578v9.507-.002z" />
      <path d="M13.285 10.272H7.918v4.057h5.367v-4.057z" fill="#BDBDBD" />
    </svg>
  );
}

const MemoCalendarIcon = React.memo(CalendarIcon);
export default MemoCalendarIcon;
