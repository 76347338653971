import React from "react";
import { ComponentProps } from "react";
import { twMerge } from "tailwind-merge";

function CrossCircleIcon(props: ComponentProps<"svg">) {
  return (
    <svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      {...props}
      className={twMerge("fill-data-primary", props.className)}
    >
      <circle cx={7.5} cy={7.5} r={7.5} />
      <path
        fill="white"
        d="M10.654 4.339a.589.589 0 00-.832.016l-2.23 2.32L5.165 4.34a.588.588 0 00-.816.847L6.76 7.508l-2.412 2.32a.588.588 0 00.816.849L7.593 8.34l2.23 2.32a.588.588 0 10.847-.815L8.424 7.508 10.67 5.17a.588.588 0 00-.016-.831z"
      />
    </svg>
  );
}

const MemoCrossCircleIcon = React.memo(CrossCircleIcon);
export default MemoCrossCircleIcon;
