import { twMerge } from "tailwind-merge";

const WatchListIcon = ({ className }: { className?: string }) => (
  <svg
    width={15}
    height={17}
    viewBox="0 0 13.311 16.548"
    className={twMerge("fill-[#225a9f]", className)}
  >
    <defs>
      <clipPath id="bksxx1h6wa">
        <path data-name="Rectangle 12600" d="M0 0h13.311v16.548H0z" />
      </clipPath>
    </defs>
    <g data-name="Group 14949">
      <g data-name="Group 14921" clipPath="url(#bksxx1h6wa)">
        <path
          data-name="Path 29450"
          d="M10.966 0H2.345A2.348 2.348 0 0 0 0 2.345V14.2a2.348 2.348 0 0 0 2.345 2.345h8.62a2.348 2.348 0 0 0 2.346-2.345V2.345A2.348 2.348 0 0 0 10.966 0m1.174 14.2a1.175 1.175 0 0 1-1.174 1.173H2.345A1.175 1.175 0 0 1 1.172 14.2V2.345a1.175 1.175 0 0 1 1.173-1.173h8.62a1.175 1.175 0 0 1 1.174 1.174z"
        />
        <path
          data-name="Path 29451"
          d="M5.782 7.9h3.227a.687.687 0 0 0 0-1.374H5.782a.687.687 0 0 0 0 1.374"
          transform="translate(-2.707 -3.469)"
        />
        <path
          data-name="Path 29452"
          d="M9.963 20.028h-4.18a.687.687 0 1 0 0 1.374h4.18a.687.687 0 1 0 0-1.374"
          transform="translate(-2.708 -10.643)"
        />
        <path
          data-name="Path 29453"
          d="M13.011 13.279H5.783a.687.687 0 0 0 0 1.374h7.229a.687.687 0 0 0 0-1.374"
          transform="translate(-2.708 -7.056)"
        />
      </g>
    </g>
  </svg>
);

export default WatchListIcon;
