import React, { ComponentProps } from "react";

function ArrowCircleIcon(props: ComponentProps<"svg">) {
  return (
    <svg width={26} height={26} viewBox="0 0 26 26" {...props}>
      <circle cx={13} cy={13} r={13} />
      <path d="M16.88 12.616a.894.894 0 010 1.264l-5.365 5.366a.894.894 0 01-1.264-1.265l4.733-4.733-4.733-4.733a.894.894 0 011.264-1.264l5.366 5.365z" />
    </svg>
  );
}

const MemoArrowCircleIcon = React.memo(ArrowCircleIcon);
export default MemoArrowCircleIcon;
