import { ComponentProps } from "react";
import { twMerge } from "tailwind-merge";

function StartIcon(props: ComponentProps<"svg">) {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      aria-label="start-icon"
      {...props}
      className={twMerge("fill-data-primary", props.className)}
    >
      <path d="M9.5 18.9999C14.7466 18.9999 19 14.7466 19 9.49993C19 4.25323 14.7466 0 9.5 0C4.25337 0 0 4.2533 0 9.5C0 14.7467 4.2533 18.9999 9.5 18.9999ZM9.5 1.51998C13.9 1.51998 17.48 5.09992 17.48 9.49993C17.48 13.8999 13.9 17.4799 9.5 17.4799C5.09999 17.4799 1.52005 13.8999 1.52005 9.49993C1.52005 5.09992 5.09957 1.51998 9.5 1.51998Z" />
      <path
        d="M13.5949 9.81854L7.72725 13.2062C7.48215 13.3477 7.17578 13.1708 7.17578 12.8878L7.17578 6.11248C7.17578 5.82946 7.48215 5.65258 7.72725 5.79409L13.5949 9.18176C13.84 9.32327 13.84 9.67703 13.5949 9.81854Z"
        strokeWidth="1.5"
      />
    </svg>
  );
}

export default StartIcon;
