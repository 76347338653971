import React from "react";
import { ComponentProps } from "react";
import { twMerge } from "tailwind-merge";

function ArrowRight(props: ComponentProps<"svg">) {
  return (
    <svg
      width={13}
      height={11}
      {...props}
      viewBox="0 0 13 11"
      className={twMerge("fill-[#40B2E4]", props.className)}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.03.254a.834.834 0 011.2 0l4.522 4.632a.883.883 0 010 1.228L8.23 10.746a.834.834 0 01-1.2 0 .883.883 0 010-1.228l3.075-3.15H.848A.858.858 0 010 5.5c0-.48.38-.868.848-.868h9.257l-3.074-3.15a.883.883 0 010-1.228z"
      />
    </svg>
  );
}

const MemoArrowRight = React.memo(ArrowRight);
export default MemoArrowRight;
