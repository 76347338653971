import { twMerge } from "tailwind-merge";

const HamburgerLeftIcon = ({
  width = 32,
  height = 24,
  className = "",
  onClick,
}: {
  width?: number;
  height?: number;
  className?: string;
  onClick?: () => void;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 27.871 15"
      className={twMerge("fill-[#3ad29f] stroke-[#3ad29f]", className)}
      onClick={onClick}
    >
      <g data-name="Group 14809">
        <path
          data-name="Combined Shape"
          d="M1 14a1 1 0 0 1-1-1v-1a1 1 0 0 1 1-1h17a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1zm0-6a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h17a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1zm0-6a1 1 0 0 1-1-1V0a1 1 0 0 1 1-1h17a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1z"
          transform="translate(8.871 1)"
        />
        <path
          d="M3.392 2.77a.44.44 0 0 1 0 .622L.751 6.033a.44.44 0 0 1-.622-.623l2.33-2.33L.129.751A.44.44 0 0 1 .751.129z"
          transform="rotate(180 1.936 5.29)"
          strokeWidth=".7px"
        />
      </g>
    </svg>
  );
};

export default HamburgerLeftIcon;
