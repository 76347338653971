const SearchIcon = ({
  iconSvgClassName,
  iconPathClassName,
}: {
  iconSvgClassName?: string;
  iconPathClassName?: string;
}) => {
  return (
    <svg
      width="17.048"
      height="17.058"
      viewBox="0 0 17.048 17.058"
      className={iconSvgClassName}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g id="Search_icon" data-name="Search icon" transform="translate(-10 -5)">
        <path
          id="Path_9119"
          data-name="Path 9119"
          d="M15.5,14h-.79l-.28-.27a6.518,6.518,0,1,0-.7.7l.27.28v.79l4.25,4.25a1.054,1.054,0,0,0,1.49-1.49Zm-6,0A4.5,4.5,0,1,1,14,9.5,4.494,4.494,0,0,1,9.5,14Z"
          transform="translate(7.001 2.001)"
          className={iconPathClassName}
        />
      </g>
    </svg>
  );
};

export default SearchIcon;
