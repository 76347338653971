import React from "react";
import { ComponentProps } from "react";
import { twMerge } from "tailwind-merge";

const ExpandIcon = (props: ComponentProps<"svg">) => {
  return (
    <svg
      viewBox="0 0 24 24"
      stroke="none"
      strokeWidth={0}
      {...props}
      className={twMerge("fill-data-primary", props.className)}
    >
      <polygon
        points="3,19 12,5 21,19"
        style={{ strokeLinejoin: "round" }}
        fill={props.fill}
      />
    </svg>
  );
};

const MemoExpandIcon = React.memo(ExpandIcon);
export default MemoExpandIcon;
