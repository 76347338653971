import { twMerge } from "tailwind-merge";

const FillArrowDownIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      className={twMerge("fill-emerald-500", className)}
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="12"
      viewBox="0 0 18 12"
    >
      <path
        data-name="Path 397"
        d="M7.419.714a2.108 2.108 0 0 1 3.162 0l6.925 7.968A2.012 2.012 0 0 1 15.925 12H2.075A2.012 2.012 0 0 1 .494 8.681z"
        transform="rotate(180 9 6)"
      />
    </svg>
  );
};

export default FillArrowDownIcon;
