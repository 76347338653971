import React, { ComponentProps } from "react";
import { twMerge } from "tailwind-merge";

function LockIcon(props: ComponentProps<"svg">) {
  const [animated, setAnimated] = React.useState(false);
  return (
    <svg
      {...props}
      onMouseEnter={() => setAnimated(true)}
      onAnimationEnd={() => setAnimated(false)}
      width={12}
      height={15}
      viewBox="0 0 12 15"
      fill="none"
      className={twMerge(
        "cursor-pointer fill-neutral-400",
        animated && "animate-shake",
        props.className
      )}
      style={{
        animationFillMode: "forwards",
        animationIterationCount: "1",
      }}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 0a3.914 3.914 0 00-2.727 1.098 3.698 3.698 0 00-1.13 2.652v2.917h-.429c-.454 0-.89.175-1.212.488A1.644 1.644 0 000 8.333v5c0 .442.18.866.502 1.179A1.74 1.74 0 001.714 15h8.572c.454 0 .89-.176 1.212-.488.321-.313.502-.737.502-1.179v-5c0-.442-.18-.866-.502-1.178a1.74 1.74 0 00-1.212-.488h-.429V3.75c0-.995-.406-1.948-1.13-2.652A3.914 3.914 0 006 0zm2.571 6.667V3.75c0-.663-.27-1.299-.753-1.768A2.61 2.61 0 006 1.25a2.61 2.61 0 00-1.818.732 2.465 2.465 0 00-.753 1.768v2.917H8.57z"
      />
    </svg>
  );
}

const MemoLockIcon = React.memo(LockIcon);
export default MemoLockIcon;
