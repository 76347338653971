import { ComponentProps } from "react";
import React from "react";
import { twMerge } from "tailwind-merge";

function AddIcon(props: ComponentProps<"svg">) {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      {...props}
      className={twMerge("fill-[#2D80AF]", props.className)}
    >
      <path d="M13.952 6.764h-5.01V1.755a1.09 1.09 0 00-2.179 0v5.009H1.755a1.09 1.09 0 000 2.178h5.008v5.01a1.09 1.09 0 002.178 0v-5.01h5.01a1.09 1.09 0 000-2.178z" />
    </svg>
  );
}

const MemoAddIcon = React.memo(AddIcon);
export default MemoAddIcon;
