import { twMerge } from "tailwind-merge";

const ArrowDownIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      role="img"
      aria-label="Arrow Down"
      className={twMerge("fill-neutral-500", className)}
      width="14"
      height="8"
      viewBox="0 0 14 8"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M7.6939 7.69471C7.50637 7.88218 7.25206 7.9875 6.9869 7.9875C6.72173 7.9875 6.46743 7.88218 6.2799 7.69471L0.279898 1.69471C0.0977392 1.50611 -0.00305462 1.25351 -0.000776291 0.991311C0.00150204 0.729114 0.10667 0.478301 0.292079 0.292893C0.477488 0.107485 0.7283 0.00231622 0.990497 3.78026e-05C1.25269 -0.00224062 1.50529 0.0985538 1.6939 0.280712L6.9869 5.57371L12.2799 0.280712C12.4685 0.0985538 12.7211 -0.00224062 12.9833 3.78026e-05C13.2455 0.00231622 13.4963 0.107485 13.6817 0.292893C13.8671 0.478301 13.9723 0.729114 13.9746 0.991311C13.9768 1.25351 13.8761 1.50611 13.6939 1.69471L7.6939 7.69471Z" />
    </svg>
  );
};

export default ArrowDownIcon;
