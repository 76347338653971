import { ComponentProps } from "react";
import { twMerge } from "tailwind-merge";

function RenameIcon(props: ComponentProps<"svg">) {
  return (
    <svg
      width={14}
      height={13.5}
      viewBox="0 0 14 13.5"
      aria-label="rename-icon"
      {...props}
      className={twMerge("fill-[#5a8fd6]", props.className)}
    >
      <path
        data-name="Path 29583"
        d="M11.267 7.019a.835.835 0 00-.835.835v4.009H1.671V3.104h4.677a.836.836 0 000-1.672H.965A.966.966 0 000 2.397v10.172a.965.965 0 00.966.965h10.171a.965.965 0 00.965-.965V7.855a.835.835 0 00-.835-.835"
      />
      <path
        data-name="Path 29584"
        d="M13.716 1.391L12.509.275A.985.985 0 0011.785 0a1.031 1.031 0 00-.714.3L5.149 6.265a.98.98 0 00-.257.453l-.53 2.093a.677.677 0 00.207.662.707.707 0 00.477.184.7.7 0 00.208-.031l2.1-.634a1.044 1.044 0 00.431-.259l5.929-5.963a.977.977 0 000-1.379m-.169.17zm-.843.522L6.954 7.871l-1.195.361.3-1.184 5.747-5.788z"
      />
    </svg>
  );
}

export default RenameIcon;
