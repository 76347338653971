import React, { ComponentProps } from "react";
import { twMerge } from "tailwind-merge";

function QuestionIcon(props: ComponentProps<"svg">) {
  return (
    <svg
      viewBox="0 0 22 22"
      {...props}
      className={twMerge("h-6 w-6 fill-[#5A8FD6]", props.className)}
    >
      <path d="M11 0a11 11 0 1011 11A11.013 11.013 0 0011 0zm0 20a9 9 0 119-9 9.011 9.011 0 01-9 9" />
      <path d="M10.77 14.71a1.264 1.264 0 00-.91.34 1.213 1.213 0 00-.008 1.709 1.269 1.269 0 00.918.335 1.269 1.269 0 00.918-.335 1.213 1.213 0 00-.008-1.71 1.263 1.263 0 00-.91-.338zM10.926 5.469a3.752 3.752 0 00-2.59.845 3.014 3.014 0 00-.965 2.338h2.266A1.4 1.4 0 0110 7.669a1.236 1.236 0 01.926-.356c.812 0 1.218.44 1.219 1.322 0 .294-.082.583-.235.834-.264.384-.581.728-.941 1.024a3.571 3.571 0 00-.97 1.279 4.7 4.7 0 00-.264 1.744h2l.03-.477c.059-.514.3-.989.68-1.338l.633-.6a5.22 5.22 0 001.04-1.311c.197-.389.3-.819.3-1.255A2.85 2.85 0 0013.5 6.27a3.773 3.773 0 00-2.57-.8" />
    </svg>
  );
}

const MemoQuestionIcon = React.memo(QuestionIcon);
export default MemoQuestionIcon;
