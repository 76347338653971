import React, { ComponentProps } from "react";

function MoneyCircleIcon(props: ComponentProps<"svg">) {
  return (
    <svg
      width={14}
      height={14}
      fill="none"
      viewBox="0 0 14 14"
      {...props}
      className={props.className}
    >
      <path
        d="M7 0C3.14 0 0 3.14 0 7s3.14 7 7 7 7-3.14 7-7-3.14-7-7-7zm0 13A6 6 0 117.001.999 6 6 0 017 13z"
        fill="#BDBDBD"
      />
      <path
        d="M7.33 6.5V4.377c.166.057.333.157.476.3.157.157.164.25.164.613 0 .22.18.4.4.4.22 0 .4-.18.4-.403 0-.4 0-.78-.4-1.177-.3-.3-.67-.496-1.044-.56v-.16c0-.22-.18-.4-.4-.4-.22 0-.4.18-.4.4v.217c-.636.18-1.473.697-1.473 1.923 0 .997.81 1.487 1.473 1.66v2.437c-.45-.157-.796-.683-.796-1.293 0-.22-.18-.4-.4-.4-.22 0-.4.18-.4.4 0 1.08.696 1.973 1.6 2.123v.16c0 .22.18.4.4.4.22 0 .4-.18.4-.4v-.187c.803-.153 1.74-.673 1.74-1.956 0-1.154-1.067-1.82-1.74-1.974zm-1.474-.97c0-.633.347-.93.67-1.073v1.89c-.323-.12-.67-.357-.67-.817zM7.33 9.61V7.34c.37.147.936.524.936 1.137 0 .74-.51 1.023-.936 1.133z"
        fill="#BDBDBD"
      />
    </svg>
  );
}

const MemoMoneyCircleIcon = React.memo(MoneyCircleIcon);
export default MemoMoneyCircleIcon;
