import { twMerge } from "tailwind-merge";

const LastPageIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      className={twMerge("fill-data-primary-dark", className)}
    >
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path d="M5.59 7.41L10.18 12l-4.59 4.59L7 18l6-6-6-6zM16 6h2v12h-2z" />
    </svg>
  );
};

export default LastPageIcon;
