import { twMerge } from "tailwind-merge";

const FirstPageIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      className={twMerge("fill-data-primary-dark", className)}
    >
      <path d="M18.41 16.59L13.82 12l4.59-4.59L17 6l-6 6 6 6zM6 6h2v12H6z" />
      <path d="M24 24H0V0h24v24z" fill="none" />
    </svg>
  );
};

export default FirstPageIcon;
