import React from "react";
import { ComponentProps } from "react";
import { twMerge } from "tailwind-merge";

function CheckCircleOutlineIcon(props: ComponentProps<"svg">) {
  return (
    <svg
      width={61}
      height={60}
      viewBox="0 0 61 60"
      {...props}
      className={twMerge("fill-[#00BC75]", props.className)}
    >
      <path
        d="M24.963 39.057l-5.77-5.903a2.125 2.125 0 01.08-3.064 2.264 2.264 0 013.143.075l4.04 4.128 11.137-13.232a2.271 2.271 0 013.133-.303 2.13 2.13 0 01.31 3.052l-12.74 15.127c-.4.482-.997.77-1.635.792-.028.002-.053.002-.083.002a2.271 2.271 0 01-1.615-.674m5.098 16.657C15.828 55.714 4.294 44.201 4.294 30S15.828 4.283 30.061 4.283c14.231 0 25.765 11.514 25.765 25.716 0 14.202-11.534 25.715-25.765 25.715m0-55.714C13.484 0 0 13.457 0 29.999 0 46.543 13.484 60 30.061 60c16.575 0 30.06-13.457 30.06-30.001C60.12 13.457 46.635 0 30.06 0"
        fillRule="evenodd"
      />
    </svg>
  );
}

const MemoCheckCircleOutlineIcon = React.memo(CheckCircleOutlineIcon);
export default MemoCheckCircleOutlineIcon;
