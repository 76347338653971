import { ComponentProps } from "react";
import { twMerge } from "tailwind-merge";

function StopIcon(props: ComponentProps<"svg">) {
  return (
    <svg
      width={19}
      height={19}
      viewBox="0 0 19 19"
      aria-label="stop-icon"
      {...props}
      className={twMerge("fill-data-primary", props.className)}
    >
      <path d="M9.5 19a9.5 9.5 0 100-19 9.5 9.5 0 000 19zm0-17.48c4.4 0 7.98 3.58 7.98 7.98s-3.58 7.98-7.98 7.98S1.52 13.9 1.52 9.5 5.1 1.52 9.5 1.52z" />
      <path d="M7.735 13.981a.95.95 0 00.95-.95v-7.22a.95.95 0 10-1.9 0v7.22c0 .525.425.95.95.95zM11.532 13.981a.95.95 0 00.95-.95v-7.22a.95.95 0 10-1.9 0v7.22c0 .525.425.95.95.95z" />
    </svg>
  );
}

export default StopIcon;
