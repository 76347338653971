import { ComponentProps } from "react";

const RegisterIcon = (props: ComponentProps<"svg">) => {
  return (
    <svg width={18} height={18} {...props}>
      <path d="M16.02 0H9.367a1.054 1.054 0 000 2.108h5.516v13.785H9.367a1.055 1.055 0 100 2.107h6.655a.978.978 0 00.98-.974V.976A.976.976 0 0016.02 0z" />
      <path d="M8.081 7.94H1.063a1.063 1.063 0 100 2.126h7.018l-2.07 1.986a.984.984 0 00-.229 1.076.986.986 0 001.593.334l3.92-3.756a.975.975 0 000-1.41L7.378 4.54a.991.991 0 00-1.601.346.975.975 0 00.233 1.063l2.07 1.99z" />
    </svg>
  );
};
export default RegisterIcon;
