import { ComponentProps } from "react";

const DiscordIcon = (props: ComponentProps<"svg">) => {
  return (
    <svg
      {...props}
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="225pt"
      height="225pt"
      viewBox="0 0 225 225"
      preserveAspectRatio="xMidYMid meet"
      aria-label="discord-icon"
    >
      <g
        transform="translate(0,225) scale(0.100000,-0.100000)"
        fill="#5F70BE"
        stroke="none"
      >
        <path
          d="M1011 2239 c-253 -22 -523 -155 -701 -345 -280 -298 -376 -732 -248
-1124 118 -364 444 -657 823 -740 161 -36 378 -29 540 16 615 171 962 822 763
1434 -47 145 -140 300 -249 415 -241 253 -570 375 -928 344z m-104 -574 c1 -5
-23 -18 -55 -28 -65 -21 -187 -78 -217 -101 -12 -9 13 -4 60 13 259 93 550 98
785 13 30 -11 64 -23 75 -27 31 -11 -44 35 -105 65 -30 15 -74 33 -97 40 -24
7 -43 17 -43 22 0 19 35 20 111 5 83 -16 160 -47 246 -99 51 -31 52 -33 102
-157 86 -215 122 -366 136 -564 l8 -99 -57 -54 c-65 -61 -152 -106 -247 -129
-105 -24 -118 -21 -164 38 -48 62 -50 67 -33 67 28 0 154 69 183 100 l30 32
-42 -21 c-81 -40 -191 -80 -269 -96 -102 -21 -285 -21 -389 0 -76 16 -263 84
-295 108 -8 6 0 -5 18 -25 30 -32 146 -98 172 -98 15 0 12 -7 -14 -38 -13 -15
-35 -40 -49 -57 l-25 -29 -78 12 c-103 17 -211 69 -281 136 -50 49 -53 55 -53
102 0 62 16 196 36 294 29 146 126 403 170 452 68 74 365 171 381 123z"
        />
        <path
          d="M792 1150 c-87 -53 -79 -205 12 -248 89 -44 191 24 191 127 0 53 -33
112 -73 130 -36 17 -94 13 -130 -9z"
        />
        <path
          d="M1289 1155 c-61 -33 -83 -115 -51 -188 42 -96 170 -107 228 -20 25
38 25 128 0 166 -36 54 -119 74 -177 42z"
        />
      </g>
    </svg>
  );
};

export default DiscordIcon;
